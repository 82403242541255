import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IonRow, IonSpinner } from '@ionic/react';
import { IonLabel } from '@ionic/react';
import './ForgotPassword.scss';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import HorizontalLabelPositionBelowStepper from '../../pages/SignUp/Steps';
import webtitleImg from '../../assets/images/brand/title.png';
import { ReactComponent as TickMark } from '../../assets/images/icons/Isolation_Mode.svg';
import { ReactComponent as Stars } from '../../assets/images/icons/starts.svg';
import SVLS_API from '../../svls-api';
import { useHistory } from 'react-router';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';

type ForgotPwdForm = {
  closeHandler: () => void;
};

const ForgotPwdForm: React.FC<ForgotPwdForm> = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [username, setUsername] = useState();

  const steps = ['Username Verification', 'Reset Password'];
  const Steps = {
    0: (
      <UsernameVerfication
        setUsername={setUsername}
        setActiveStep={setActiveStep}
      />
    ),
    1: <ResetPassword username={username} setActiveStep={setActiveStep} />,
    2: <ResetPasswordSuccess />,
  };

  return (
    <div className="fgt-pwd">
      <div className="title-row">
        <img src={webtitleImg} alt="title" className="logo" />
      </div>
      <div className="form-ctn">
        {activeStep !== 2 && (
          <>
            <div className="page-title">Reset password</div>
            <HorizontalLabelPositionBelowStepper
              activeStep={activeStep}
              steps={steps}
            />
          </>
        )}

        {Steps[activeStep]}
      </div>
    </div>
  );
};

type UserVerificationProps = {
  setActiveStep: Function;
  setUsername: Function;
};

function UsernameVerfication(props: UserVerificationProps) {
  const { setActiveStep, setUsername } = props;

  const [progress, setProgress] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(null);
  const [option, setOption] = useState("Username");

  const formik = useFormik({
    initialValues: { username: '' },
    validationSchema: Yup.object({
      username: Yup.string()
        .required('Required')
        .min(4)
        .max(20)
        .trim()
        .matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
    }),
    onSubmit: (values) => {
      setErrorMsg(null);

      forgotPassword(values.username);
    },
  });

  useEffect(() => {
    setUsername(formik.values.username?.toLowerCase());
  }, [formik.values.username]);

  const forgotPassword = async (username: string) => {
    try {
      setProgress(true);
      await SVLS_API.post(
        `/account/v2/users/${username}/:sendResetPasswordOtp`
      );

      setActiveStep((prev) => prev + 1);
    } catch (err) {
      setErrorMsg(err.response?.data?.message);
    } finally {
      setProgress(false);
    }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="forgot-pwd-form-ctn"
      autoComplete="off"
    >
      <div className="usr-input">
        {/* <select
          className="input-label option-selection"
          name="option"
          onChange={(e) => {
              console.log("option:", e.target.value);
              setOption(e.target.value);
            }
          }
        >
          <option value="Username">Username</option>
          <option value="Phone number">Phone number</option>
        </select> */}
        <IonLabel className="input-label">
          {option === "Username" ? "Username" : "Phone number"} <span className="required">*</span>
        </IonLabel>
        <TextField
          className="login-input-field user-name"
          type="text"
          name="username"
          placeholder={option === "Username" ? "Enter username" : "Enter Phone number"}
          variant="outlined"
          autoFocus
          error={
            formik.touched.username && formik.errors.username ? true : false
          }
          helperText={
            formik.touched.username && formik.errors.username
              ? formik.errors.username
              : null
          }
          {...formik.getFieldProps('username')}
        />
      </div>

      <span className="error-msg">{errorMsg}</span>

      <Button
        className="confirm-btn"
        color="primary"
        endIcon={progress ? <IonSpinner name="lines-small" /> : ''}
        type="submit"
        variant="contained"
        disabled={!formik.isValid}
      >
        Forgot password
      </Button>

      <IonRow className="msg-row">
        <div className="msg">
          Have an account? <NavLink to="/login">Sign In</NavLink>
        </div>
        <div className="msg back-home">
          <NavLink to="/home">Back to Home</NavLink>
        </div>
      </IonRow>
    </form>
  );
}

type ResetPasswordRequest = {
  resetPassword: string;
};

type ResetPasswordProps = {
  username: string;
  setActiveStep: Function;
};

function ResetPassword(props: ResetPasswordProps) {
  const { username, setActiveStep } = props;

  const history = useHistory();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errMsg, setErrMsg] = useState();

  const formik = useFormik({
    initialValues: { otp: '', newPassword: '', confirmPassword: '' },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required('Required')
        .trim()
        .length(6)
        .matches(/^[0-9]+$/, 'Only numbers are allowed'),
      newPassword: Yup.string()
        .required('Required')
        .trim()
        .min(8, 'Password must be at least 8 characters'),
      confirmPassword: Yup.string()
        .required('Required')
        .test(
          'Confirm password check',
          'Password mismatch',
          function confirmPasswordCheck(confirmPassword) {
            return confirmPassword === this.parent.newPassword;
          }
        ),
    }),
    onSubmit: (values) => {
      setErrMsg(null);

      const resetPwdReq = {
        resetPassword: values.newPassword,
      };
      resetPassword(username, values.otp, resetPwdReq);
    },
  });

  const resetPassword = async (
    username: string,
    otp: string,
    request: ResetPasswordRequest
  ) => {
    try {
      setLoading(true);
      const response = await SVLS_API.put(
        `/account/v2/users/${username}/password:resetOnline`,
        request,
        {
          params: {
            otp: otp,
          },
        }
      );

      setActiveStep((prev) => prev + 1);
      setTimeout(() => history.replace('/login'), 1500);
    } catch (err) {
      console.log(err);

      setErrMsg(err.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className="forgot-pwd-form-ctn"
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <div className="usr-input">
        <IonLabel className="input-label">
          Otp<span className="required">*</span>
        </IonLabel>
        <TextField
          className="login-input-field user-name"
          placeholder="Enter otp"
          type="text"
          name="otp"
          variant="outlined"
          autoFocus
          error={formik.touched.otp && formik.errors.otp ? true : false}
          helperText={
            formik.touched.otp && formik.errors.otp ? formik.errors.otp : null
          }
          {...formik.getFieldProps('otp')}
        />
      </div>

      <div className="usr-input">
        <IonLabel className="input-label">
          New password<span className="required">*</span>
        </IonLabel>
        <FormControl
          className="login-input-field pwd-field"
          variant="outlined"
          error={
            formik.touched.newPassword && formik.errors.newPassword
              ? true
              : false
          }
        >
          <OutlinedInput
            id="standard-adornment-password"
            type={showNewPassword ? 'text' : 'password'}
            name="newPassword"
            placeholder="Enter new password"
            {...formik.getFieldProps('newPassword')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {formik.touched.newPassword && formik.errors.newPassword ? (
            <FormHelperText error id="my-helper-text">
              {formik.errors.newPassword}
            </FormHelperText>
          ) : null}
        </FormControl>
      </div>

      <div className="usr-input">
        <IonLabel className="input-label">
          Confirm password<span className="required">*</span>
        </IonLabel>
        <FormControl
          className="login-input-field pwd-field"
          variant="outlined"
          error={
            formik.touched.confirmPassword && formik.errors.confirmPassword
              ? true
              : false
          }
        >
          <OutlinedInput
            id="standard-adornment-password"
            type={showConfirmPassword ? 'text' : 'password'}
            name="confirmPassword"
            placeholder="Enter confirm password"
            {...formik.getFieldProps('confirmPassword')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  {showConfirmPassword ? (
                    <Visibility className="no-margin" />
                  ) : (
                    <VisibilityOff className="no-margin" />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <FormHelperText error id="my-helper-text">
              {formik.errors.confirmPassword}
            </FormHelperText>
          ) : null}
        </FormControl>
      </div>

      <span className="error-msg">{errMsg}</span>

      <Button
        className="confirm-btn"
        color="primary"
        endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
        type="submit"
        variant="contained"
        disabled={!formik.isValid}
      >
        Reset password
      </Button>
    </form>
  );
}

function ResetPasswordSuccess(props) {
  return (
    <div className="reset-sucess">
      <TickMark className="tick-success center" />
      <Stars className="center" />
      <div className="message">Password successfully changed</div>
    </div>
  );
}
export default ForgotPwdForm;
