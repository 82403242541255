import React, { useState, useCallback, useEffect } from 'react';
import './WelcomeBonus.scss';
import {
  IonRow,
  IonCol,
  IonButton,
} from '@ionic/react';
import { BRAND_DOMAIN, BRAND_NAME } from '../../constants/Branding';
import { useHistory } from 'react-router-dom';
import welcometitle_logo from '../../assets/images/b2cbanner/welcometitle.png';
import SportsBetting from '../../assets/images/faricimage/SportsBetting.png';
import x7Support from '../../assets/images/faricimage/24x7Support.png';
import LiveCasino from '../../assets/images/faricimage/LiveCasino.png';
import bankicon from '../../assets/images/faricimage/bank.png';
import googlepayicon from '../../assets/images/faricimage/googlepay.png';
import paytmicon from '../../assets/images/faricimage/paytm.png';
import Phonepeicon from '../../assets/images/faricimage/Phonepe.png';
import UPIicon from '../../assets/images/faricimage/UPI.png';
import VirtualSports from '../../assets/images/faricimage/VirtualSports.png';
import { ShowWelcomePage } from '../../constants/WhitelabelConfig';


const WelcomeBonus: React.FC = () => {
  let history = useHistory();
  useEffect(() => {
   if(!ShowWelcomePage[BRAND_DOMAIN]){
    history.push('/home')
   }
  }, [ShowWelcomePage[BRAND_DOMAIN]]);
  const onRedirect= async () => {
    if(BRAND_NAME.toLocaleLowerCase() === 'faircric'){
      window.location.href = 'https://www.faircric.com';
    }
    else if (BRAND_NAME.toLocaleLowerCase() === 'mazaa365'){
      window.location.href = 'https://www.mazaa365.com';
    }else if(BRAND_NAME.toLocaleLowerCase() === 'betex247'){
      window.location.href = 'https://www.betex247.com';
    }
   
  }
  const signupHandler = () => {
    if(BRAND_DOMAIN.toString() === "faircric.in"){
       window.location.href = 'https://www.faircric.com/register';
    }
  };
  return (
    <div className="welcome-ctn"> 
      <div className="welcome-card">
        <div className='wel-item-ctn'>
          <IonRow >
        <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen'>
          <div className='welcome_logo'>
          <img  src={welcometitle_logo} />
          </div>
          </IonCol>
          <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen'>
            <div className='text1_align'>PLAY THE GAME OF WINNING</div>
          </IonCol>
          <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen'>
            <div className='text_align_WelBonus'>Welcome Bonus</div>
          </IonCol>
          <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen'>
          
     
            <div className='text_algin_2'>200%</div>
           
           
           
              <div>
              <span className='cashback_align'>cashback on first deposit</span>
              </div>
           
         
          </IonCol>
          <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen mrl25'>
          <IonRow>
            <IonCol sizeLg="6" sizeSm="6" sizeXs="6" className='content_align_cen'>
                <div  className='service_align'><img src={SportsBetting}  className='icon_align'/>&nbsp;Sports Games</div>
            </IonCol>
            <IonCol sizeLg="6" sizeSm="6" sizeXs="6" className='content_align_cen'>
                <div  className='service_align'> <img src={LiveCasino}  className='icon_align'/>&nbsp;Live Games</div>
            </IonCol>
            <IonCol sizeLg="6" sizeSm="6" sizeXs="6" className='content_align_cen'>
                <div  className='service_align'> <img src={VirtualSports}  className='icon_align'/>&nbsp;Virtual Sports</div>
            </IonCol>
            <IonCol sizeLg="6" sizeSm="6" sizeXs="6" className='content_align_cen'>
                <div  className='service_align'> <img src={x7Support}  className='icon_align'/>&nbsp;24x7 Support</div>
            </IonCol>
          </IonRow>
          </IonCol>
          {BRAND_DOMAIN.toString() === 'faircric.in'? (
            <>
            <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen'>
            {/* <a> */}
            <IonRow>
            <IonCol sizeLg="6" sizeSm="6" sizeXs="6" className='content_align_cen'>
            <IonButton className="my-bets-btn withdraw-btn actions-btn claim-btn-fair"
                    onClick={() =>onRedirect()}>
                    Claim Now
            </IonButton>
            </IonCol>
            <IonCol sizeLg="6" sizeSm="6" sizeXs="6" className='content_align_cen'>
            <IonButton className="my-bets-btn withdraw-btn actions-btn claim-btn-fair"
                    onClick={signupHandler}>
                    Sign Up
                </IonButton>
            </IonCol>
            </IonRow>
            {/* </a> */}
          </IonCol>
            </>
          ):null}
          {BRAND_DOMAIN.toString() !== 'faircric.in'? (
            <>
              <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen'>
              <IonButton className="my-bets-btn withdraw-btn actions-btn claim-btn-wel"
                onClick={() =>onRedirect()}>
                Claim Now
              </IonButton>
              </IonCol>
            </>
          ):null}
          <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen'>
          <div className='text1_align'>Available on</div>
          </IonCol>
          <IonCol sizeLg="12" sizeSm="12" sizeXs="12" className='content_align_cen payment-method'>
            <div className='payment-icons-block'>
              <img src={bankicon}  className='icon_bank_align'/>
              <img src={paytmicon}  className='icon_paytm_align'/>
              <img src={UPIicon}  className='icon_upi_align'/>
              <img src={Phonepeicon}  className='icon_phonepe_align'/>
              <img src={googlepayicon}  className='icon_googleplay_align'/>

            </div>
          </IonCol>
          </IonRow>
        </div>
      </div>
    </div>
  );
};
  
export default (WelcomeBonus);
